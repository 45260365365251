import Image from 'next/image'
import { Col, Container, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import { AiOutlineTwitter, AiFillLinkedin, AiOutlineInstagram } from 'react-icons/ai'
import { FaFacebookF } from 'react-icons/fa'
import styles from '../../styles/footer.module.css';
import Link from 'next/link'
const Footer = () => {
    return (
        <footer className={styles.footer}>
        <Container>
        <Row className="footer-container">
          <Col md={4}>
            <div className="logo-area">
             <Link href="/"><a> <img src="/logo.svg" alt="programming hero" width={270} height={35} /></a></Link>
            </div>
            <p className="text-white pt-3">Programming Hero is a fun, interactive,<br /> visual, and friendly way to learn programming.</p>
          </Col>
          <Col md={4}>
            <div className="footer-nav-container d-flex justify-content-around">
              <div className="d-flex flex-column left">
                <ListGroupItem>
                     <Link href="/about"><a>About us</a></Link> 
                </ListGroupItem>
                <ListGroupItem>
                     <Link href="/blog"><a>Blog</a></Link> 
                </ListGroupItem>
                <ListGroupItem>
                     <Link href="/account"><a>Account</a></Link> 
                </ListGroupItem>
                <ListGroupItem>
                     <Link href="/about"><a>Contact</a></Link> 
                </ListGroupItem>
              </div>
              <div className="d-flex flex-column">
              <ListGroupItem>
                     <Link href="/about"><a>Terms </a></Link> 
                </ListGroupItem>
                <ListGroupItem>
                     <Link href="/blog"><a>Privacy</a></Link> 
                </ListGroupItem>
                <ListGroupItem>
                     <Link href="/account"><a>Help</a></Link> 
                </ListGroupItem>
                <ListGroupItem>
                     <Link href="/about"><a>FAQ</a></Link> 
                </ListGroupItem>

              </div>
            </div>
          </Col>
          <Col md={4} className="social-container">
            <p className="text-white text-center">Follow Us</p>
            <div className="d-flex justify-content-center">
              <ListGroupItem ><span><FaFacebookF size={26} /></span></ListGroupItem>
              <ListGroupItem ><span><AiOutlineTwitter size={26} /></span></ListGroupItem>
              <ListGroupItem ><span><AiOutlineInstagram size={26} /></span></ListGroupItem>
              <ListGroupItem ><span><AiFillLinkedin size={26} /></span></ListGroupItem>
            </div>
          </Col>
        </Row>
      </Container>
     </footer>
    );
};

export default Footer;