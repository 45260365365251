import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/globals.css';
import { Provider } from 'next-auth/client';
import Footer from '../components/footer/Footer';

function MyApp({ Component, pageProps }) {
  return (  
  <Provider session={pageProps.session}>
    <Component {...pageProps} />
    <Footer/>
  </Provider>
  )
   
}

export default MyApp
